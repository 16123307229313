export const MODAL_ACTIONS = {
  TOGGLE_MODAL_SUBSCRIBE: 'MODAL::TOGGLE_MODAL_SUBSCRIBE',
  TOGGLE_MODAL_UNSUBSCRIBE: 'MODAL::TOGGLE_MODAL_UNSUBSCRIBE',
  RESET: 'MODAL::RESET'
};

export interface IModalActions {
  toggleModalSubscribe: () => void;
  toggleModalUnsubscribe: () => void;
}

export const closeModal = () => ({
  type: MODAL_ACTIONS.RESET
});

export const toggleModalSubscribe: IModalActions['toggleModalSubscribe'] = () => ({
  type: MODAL_ACTIONS.TOGGLE_MODAL_SUBSCRIBE
});

export const toggleModalUnsubscribe: IModalActions['toggleModalUnsubscribe'] = () => ({
  type: MODAL_ACTIONS.TOGGLE_MODAL_UNSUBSCRIBE
});
