export const LOCALE_ACTIONS = {
  CHANGE_LOCALE: 'LOCALE::CHANGE_LOCALE'
};

export interface ILocaleActions {
  changeLocale: (lng: string) => void;
}

export const changeLocale: ILocaleActions['changeLocale'] = lng => ({
  type: LOCALE_ACTIONS.CHANGE_LOCALE,
  payload: lng
});
