//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';

import useMobile from '../../hooks/use.mobile.hook';
import stylesMenuDesktop from './menu.desktop.styles';
import logo from '../../assets/svg/logo.svg';
import { IMenuProps } from '../../models/types';
import { LocaleService, TLocaleServiceProps } from '../../services/locale.service';
import { changeLocale } from '../../utils/util.locale';
//#endregion

interface IMenuDesktopProps extends IMenuProps {
  localeService: TLocaleServiceProps;
}

const MenuDesktop: FC<IMenuDesktopProps> = ({ items, localeService }) => {
  const { actions, locales, currentLocale } = localeService;
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesMenuDesktop({ isMobile });

  const toggleLocale = () => {
    changeLocale({
      currentLocale,
      locales,
      actions: {
        changeLocale: actions.changeLocale
      }
    });
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.container }>
        <span className={ classes.logoContainer }>
          <img src={ logo }/>
          <span className={ classes.logoText } dangerouslySetInnerHTML={ { __html: t('menu.logo') } }/>
        </span>
        { items.map(el =>
          <a href={ el.link } key={ el.number } className={ classes.navLink } onClick={ el.action }>
            { el.title }
          </a>
        ) }
        <span onClick={ toggleLocale } className={ classes.locale }>{ currentLocale }</span>
      </div>
    </div>
  );
};

export default flow([LocaleService])(MenuDesktop);
