//#region imports
import { AnyAction, CombinedState, combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';

import modal, { TModalState } from './modal/modal.reducer';
import locale, { TLocaleState } from './locale/locale.reducer';
//#endregion

export interface IRootState {
  readonly router: RouterState;
  readonly modal: TModalState;
  readonly locale: TLocaleState;
}

const rootReducer = (history: History) => (state: CombinedState<IRootState> | undefined, action: AnyAction) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    modal,
    locale
  })(action.type === 'ROOT:RESET' ? undefined : state, action);

export default rootReducer;
