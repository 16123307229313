//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';

import useMobile from '../../../hooks/use.mobile.hook';
import stylesMenuMobile from '../menu.mobile.styles';
import logoInverse from '../../../assets/svg/logo.inverse.svg';
import closeLight from '../../../assets/svg/close.light.svg';
import { IMenuProps } from '../../../models/types';
import { LocaleService, TLocaleServiceProps } from '../../../services/locale.service';
import { changeLocale } from '../../../utils/util.locale';
//#endregion

interface IDrawerProps extends IMenuProps {
  toggleDrawer: () => void;
  localeService: TLocaleServiceProps;
}

const MenuMobileDrawer: FC<IDrawerProps> = ({ items, toggleDrawer, localeService }) => {
  const { actions, locales, currentLocale } = localeService;
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const classes = stylesMenuMobile({ isMobile, isInverse: true });

  const toggleLocale = () => {
    changeLocale({
      currentLocale,
      locales,
      actions: {
        changeLocale: actions.changeLocale
      }
    });
  };

  const localesList = locales.map((el: string) => {
    return (
      <span
        key={ el }
        onClick={ toggleLocale }
        className={ `${ classes.button } ${ el === currentLocale ? classes.activeButton : '' }` }
      >
        { el }
      </span>
    );
  });

  return (
    <div className={ classes.drawer }>
      <div className={ classes.header }>
        <div className={ classes.header }>
          <img className={ classes.logo } src={ logoInverse }/>
          <span className={ classes.logoText } dangerouslySetInnerHTML={ { __html: t('menu.logo') } }/>
        </div>
        <img className={ classes.close } src={ closeLight } onClick={ toggleDrawer }/>
      </div>
      <div className={ classes.list }>
      { items.map(el => {
        return (
          el.action ?
            <div key={ el.number } onClick={ el.action } className={ classes.link }>
              { el.title }
            </div> :
            <a
              key={ el.number }
              className={ classes.link }
              href={ el.link }
              onClick={ toggleDrawer }
            >
            { el.title }
            </a>
          );
        }
      ) }
      </div>
      <div className={ classes.buttons }>
        { localesList }
      </div>
      <div className={ classes.info } dangerouslySetInnerHTML={ { __html: t('cards.desc2') } } />
    </div>
  );
};

export default flow([LocaleService])(MenuMobileDrawer);
