//#region imports
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../entities/root.reducer';
import { TModalState } from '../entities/modal/modal.reducer';

import { connect } from './connect';

import { closeModal, toggleModalSubscribe, toggleModalUnsubscribe } from '../entities/modal/modal.actions';
//#endregion

const mapStateToProps = (state: IRootState): TModalState => state.modal;

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, unknown, any>) => ({
  toggleModalSubscribe: () => dispatch(toggleModalSubscribe()),
  toggleModalUnsubscribe: () => dispatch(toggleModalUnsubscribe()),
  closeModal: () => dispatch(closeModal())
});

export type TModalServiceProps = ReturnType<typeof mapStateToProps> & {
  actions: ReturnType<typeof mapDispatchToProps>;
};

export const ModalService = connect<TModalServiceProps>('modalService', mapStateToProps, mapDispatchToProps);
