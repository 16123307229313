//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    wrapper: {
      margin: ({ isMobile }) => isMobile ? '45px 20px 0 20px' : '35px 50px 0 50px',
      borderTop: `1px solid ${ customTheme.colors.neutral }`
    },
    item: {
      display: 'block',
      margin: '15px 0',
      color: customTheme.colors.neutral,
      fontSize: 14
    }
  }),
  { name: 'Footer' }
);
