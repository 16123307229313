const ru = {
  action: {
    participate: 'Участвовать',
    disable: 'Отключить',
    support: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a>'
  },
  menu: {
    logo: 'Национальная<br />лотерея',
    participate: 'Участвовать в лотерее',
    prizes: 'Призы',
    rules: 'Правила лотереи',
    feedback: 'Обратная связь',
    disable: 'Отключить лотерею',
    localeKz: 'kz',
    localeRu: 'ru',
    menu: 'Меню'
  },
  common: {
    titleApp: 'Национальная лотерея'
  },
  banner: {
    header: 'Выиграйте миллион в лотерее «Удача»',
    label: 'Моментальная лотерея с денежными призами для абонентов Beeline!',
    desc: 'Оформите подписку и получайте новый билет каждый день. ',
    appeal: 'Чем больше билетов, тем выше шанс выиграть приз!'
  },
  promo: {
    header: 'Участвуйте каждый день',
    desc: 'Оформите подписку на лотерею «Удача» и получайте по одному лотерейному билету в SMS каждый день.',
    appeal: 'Чем больше билетов, тем выше шанс выиграть миллион!'
  },
  prizes: {
    header: 'Призы',
    label: 'Призовой фонд лотереи «Удача»:',
    prize0: '1 324 088 призов по 100 ₸',
    prize1: '1 269 280 призов по 200 ₸',
    prize2: '222 538 призов по 400 ₸',
    prize3: '31 960 призов по 1 000 ₸',
    prize4: '4 176 призов по 2 000 ₸',
    prize5: '2 088 призов по 4 000 ₸',
    prize6: '1 080 призов по 10 000 ₸',
    prize7: '132 приза по 18 000 ₸',
    superPrizeLabel: '3 супер-приза',
    superPrize: '1 000 000 ₸',
    buttonSubscribe: 'Хочу выиграть миллион!',
    buttonUnsubscribe: 'Отключить лотерею'
  },
  cards: {
    desc1: 'Лотерея «Удача» разработана национальной лотереей\n' +
      'Республики Казахстан АО «Сәтті Жұлдыз»',
    desc2: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a>'
  },
  rules: {
    header: 'Правила лотереи',
    rule0: 'Подключите подписку на лотерею «Удача» и получайте по 1 лотерейному билету каждый день.',
    rule1: 'Билет разыгрывается моментально: Вы сразу узнаете, выиграли Вы ли нет',
    rule2: 'Если Ваш билет выиграл, денежный приз будет мгновенно зачислен на Ваш абонентский счет. ' +
      'Если Вы выиграете супер-приз, мы свяжемся с Вами для передачи выигрыша.',
    rule3: 'Ваш билет не выиграл? Не расстраивайтесь! Вы можете купить дополнительный лотерейный билет и попытать удачу вновь.',
    rule4: 'Участвуйте в лотерее каждый день, чтобы увеличить свои шансы выиграть миллион!',
    button: 'Все правила лотереи'
  },
  winners: {
    title: 'Победители лотереи',
    winner0: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner1: {
      name: 'Нурлан С.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner2: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    }
  },
  modal: {
    subscribe: {
      title: 'Участвуйте в лотерее',
      desc: 'Получайте лотерейные билеты в SMS каждый день и попробуйте выиграть миллион!<br /><br />' +
        'Для участия в лотерее «Удача» для абонентов Beeline у вас должна быть оформлена подписка, стоимость 100 ₸/день<br /><br />' +
        '<b>Для регистрации и активации подписки</b> наберите на мобильном телефоне USSD <a href="tel:*88%23">*88#</a><br /><br />' +
        '<b>Чтобы купить еще один или несколько билетов</b>, наберите *88*N#, где N количество билетов от 1 до 10. Например, чтобы купить 10 билетов, наберите <a href="tel:*88*10%23">*88*10#</a>' +
        ' Стоимость каждого купленного билета 100 ₸<br /><br />' +
        'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер ' +
        '<a href="tel:8-800-080-9351">8-800-080-9351</a>',
      button: 'Хорошо!'
    },
    unsubscribe: {
      title: 'Управление подпиской',
      desc: 'Продолжая участвовать в лотерее с ежедневными билетами вы получаете шанс выиграть миллион тенге!<br /><br />' +
        'Для отключения подписки на лотерею наберите на мобильном телефонe USSD <a href="tel:*88*0%23">*88*0#</a> ' +
        'или позвоните на горячую линию <a href="tel:8-800-080-9351">8-800-080-9351</a>',
      button: 'Хорошо!'
    }
  },
  card: {
    desc: 'Лотерея «Удача» разработана национальной лотереей\n' +
      'Республики Казахстан АО «Сәтті Жұлдыз»',
    feedback: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8&#8209;800&#8209;080&#8209;9351</a>'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © АО «Сәтті Жұлдыз»`,
    feedback: 'Обратная связь',
    terms: 'Пользовательское соглашение'
  }
};

export default ru;
