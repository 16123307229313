//#region imports
import React, { FC, ReactNode } from 'react';

import stylesWrapper from './wrapper.styles';
import useMobile from '../../hooks/use.mobile.hook';
//#endregion

interface IWrapperProps {
  children: ReactNode;
  className?: string;
}

const Wrapper: FC<IWrapperProps> = ({ children, className }) => {
  const { isMobile } = useMobile();
  const classes = stylesWrapper({ isMobile });

  return (
    <div className={ `${className} ${classes.root}` }>{ children }</div>
  );
};

export default Wrapper;
