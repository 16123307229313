export interface IMenuItemProps {
  number: any;
  title: string;
  link: string;
  action?: () => void;
}

export interface IMenuProps {
  items: IMenuItemProps[];
}

export enum EModalTypes {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE'
}

export enum EButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  outlined = 'outlined'
}
