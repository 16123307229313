//#region imports
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import flow from 'lodash/fp/flow';

import useMobile from '../../hooks/use.mobile.hook';
import stylesMenuMobile from './menu.mobile.styles';
import logo from '../../assets/svg/logo.svg';
import { IMenuProps } from '../../models/types';
import MenuMobileDrawer from './components/menu.mobile.drawer';
import { LocaleService, TLocaleServiceProps } from '../../services/locale.service';
import { changeLocale } from '../../utils/util.locale';
//#endregion

interface IMenuMobileProps extends IMenuProps {
  localeService: TLocaleServiceProps;
}

const MenuMobile: FC<IMenuMobileProps> = ({ items, localeService }) => {
  const { actions, locales, currentLocale } = localeService;
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesMenuMobile({ isMobile });

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleLocale = () => {
    changeLocale({
      currentLocale,
      locales,
      actions: {
        changeLocale: actions.changeLocale
      }
    });
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.container }>
        <span className={ `${ classes.menuButton } ${ classes.menu }` } onClick={ toggleDrawer }>
          { t('menu.menu') }
          <Drawer className={ classes.drawerWrapper } open={ isOpen } onClose={ toggleDrawer } variant="persistent">
            <MenuMobileDrawer items={ items } toggleDrawer={ toggleDrawer }/>
          </Drawer>
        </span>
        <div className={ classes.logoContainer }>
          <img className={ classes.logo } src={ logo }/>
          <span className={ classes.logoText } dangerouslySetInnerHTML={ { __html: t('menu.logo') } }/>
        </div>
        <span onClick={ toggleLocale } className={ `${ classes.menuButton } ${ classes.locale }` }>{ currentLocale }</span>
      </div>
    </div>
  );
};

export default flow([LocaleService])(MenuMobile);
