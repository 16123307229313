//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    wrapper: {
      width: ({ isMobile }) => isMobile ? 'unset' : 745,
      margin: ({ isMobile }) => isMobile ? '0 20px' : '0 auto',
      display: 'flex',
      justifyContent: 'space-between'
    },
    container: {
      flexBasis: '41%',
      display: 'flex',
      alignItems: 'center',
      margin: ({ isMobile }) => isMobile ? '0 20px' : '55px 0 0 0',
      padding: ({ isMobile }) => isMobile ? '15px 20px' : '30px 10px 30px 20px',
      backgroundColor: customTheme.colors.blue,
      color: customTheme.colors.white,
      borderRadius: 15,
      border: `1px solid ${ customTheme.colors.grey }`
    },
    icon: {
      marginRight: 20
    },
    desc: {
      fontSize: ({ isMobile }) => isMobile ? 14 : 16,
      lineHeight: ({ isMobile }) => isMobile ? '18px' : '20px',
      '& a': {
        color: customTheme.colors.white
      }
    }
  }),
  { name: 'Card' }
);
