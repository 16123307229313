//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';

import stylesCard from '../card.styles';
import useMobile from '../../../hooks/use.mobile.hook';
import logo from '../../../assets/img/logo.webp';
//#endregion

const CardDesc = () => {
  const { isMobile } = useMobile();
  const classes = stylesCard({ isMobile });
  const { t } = useTranslation();

  return (
    <span className={ classes.container }>
      <img className={ classes.icon } src={ logo }/>
      <span className={ classes.desc }>{ t('card.desc') }</span>
    </span>
  );
};

export default CardDesc;
