//#region imports
import { MODAL_ACTIONS } from './modal.actions';
import { IModalInitialState } from './modal.model';
import { EModalTypes } from '../../models/types';
//#endregion

const initialState: IModalInitialState = {
  show: false,
  type: null as unknown as EModalTypes
};

export type TModalState = Readonly<typeof initialState>;

export default (state: TModalState = initialState, action: { type: any; }): TModalState => {
  const { type } = action;

  switch (type) {
    case MODAL_ACTIONS.TOGGLE_MODAL_SUBSCRIBE:
      return {
        ...state,
        show: true,
        type: EModalTypes.SUBSCRIBE
      };
    case MODAL_ACTIONS.TOGGLE_MODAL_UNSUBSCRIBE:
      return {
        ...state,
        show: true,
        type: EModalTypes.UNSUBSCRIBE
      };
    case MODAL_ACTIONS.RESET:
      return initialState;
    default:
      return state;
  }
};
