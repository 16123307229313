//#region imports
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import rootReducers, { IRootState } from './root.reducer';
//#endregion

import history from '../configs/router/router.history';

const defaultMiddlewares = [
  routerMiddleware(history as any)
];
const composedMiddlewares = (middlewares: any) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const store = (initialState?: IRootState, middlewares: any = []) =>
  createStore(rootReducers(history), initialState as any, composedMiddlewares(middlewares));
type RootReducerType = typeof rootReducers;

export type TStore = ReturnType<RootReducerType>;
export default store;
