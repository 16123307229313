//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
  isInverse?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      minHeight: 82
    },
    container: {
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100vw',
      maxWidth: '100%',
      padding: 20
    },
    drawer: {
      width: '100vw',
      height: '100vh',
      maxWidth: '100%',
      backgroundColor: customTheme.colors.blue
    },
    drawerWrapper: {},
    menuButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: customTheme.colors.blue,
      borderRadius: '50px',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
      fontSize: 14,
      fontFamily: customTheme.fontFamily.interBold,
      cursor: 'pointer'
    },
    locale: {
      width: 30,
      height: 30,
      fontFamily: customTheme.fontFamily.interBold,
      textTransform: 'uppercase'
    },
    menu: {
      width: 63,
      height: 30,
      cursor: 'pointer'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    logo: {
      height: ({ isMobile }) => isMobile ? 'unset' : 70,
      width: ({ isMobile }) => isMobile ? 80 : 'unset'
    },
    logoText: {
      marginLeft: 10,
      fontSize: 14,
      color: ({ isInverse }) => isInverse ? customTheme.colors.white : customTheme.colors.blue
    },
    close: {
      width: 28,
      height: 28,
      cursor: 'pointer'
    },
    header: {
      height: 91,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 25px 0 10px'
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 25px'
    },
    link: {
      padding: '16px 0',
      color: customTheme.colors.white,
      textDecoration: 'none',
      fontSize: 16,
      fontFamily: customTheme.fontFamily.interBold,
      borderBottom: `1px solid ${ customTheme.colors.white }`
    },
    buttons: {
      width: 128,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '40px auto 0 auto'
    },
    button: {
      width: 44,
      height: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: customTheme.colors.white,
      border: `2px solid ${ customTheme.colors.white }`,
      borderRadius: '50%',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: 16
    },
    activeButton: {
      color: customTheme.colors.blue,
      backgroundColor: customTheme.colors.white
    },
    info: {
      width: '80%',
      margin: '40px auto 0 auto',
      color: customTheme.colors.white,
      fontFamily: customTheme.fontFamily.inter,
      fontSize: 16,
      lineHeight: '20px',
      textAlign: 'center',
      '& a': {
        color: customTheme.colors.white,
        fontFamily: customTheme.fontFamily.interBold
      }
    }
  }),
  { name: 'MenuMobile' }
);
