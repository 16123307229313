//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useMobile from '../../hooks/use.mobile.hook';
import stylesBanner from './banner.styles';
import bannerImg from '../../assets/img/banner.webp';
import Button from '../../components/button/button';
import { EButtonVariant } from '../../models/types';
import flow from 'lodash/fp/flow';
import { ModalService, TModalServiceProps } from '../../services/modal.service';
//#endregion

interface IBannerProps {
  modalService: TModalServiceProps;
}

const Banner: FC<IBannerProps> = ({ modalService }) => {
  const { actions } = modalService;
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesBanner({ isMobile });

  return (
    <section className={ classes.root }>
      <div className={ classes.container }>
        { isMobile && <h1 className={ classes.header }>{ t('banner.header') }</h1> }
        <div className={ classes.content }>
          { !isMobile && <img className={ classes.picture } src={ bannerImg } alt={ t('banner.header') }/> }
          <div className={ classes.text }>
            { !isMobile && <h1 className={ classes.header }>{ t('banner.header') }</h1> }
            <h4 className={ classes.desc }>{ t('banner.label') }</h4><br />
            <span className={ classes.desc }>{ t('banner.desc') }</span><br />
            <span className={ classes.desc }><b>{ t('banner.appeal') }</b></span><br />
            <div className={ classes.buttons }>
              <Button
                className={ classes.button }
                title={ t('action.participate') }
                isMobile={ isMobile }
                onClick={ actions.toggleModalSubscribe }
              />
              <Button
                variant={ EButtonVariant.secondary }
                className={ classes.button }
                title={ t('action.disable') }
                isMobile={ isMobile }
                onClick={ actions.toggleModalUnsubscribe }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default flow([ModalService])(Banner);
