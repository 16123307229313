//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../configs/theme/theme.config';
import { Theme } from '@material-ui/core/styles/createTheme';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<Theme, IProps>(theme =>
  createStyles({
    button: {
      minWidth: ({ isMobile }) => isMobile ? 130 : 229,
      height: ({ isMobile }) => isMobile ? 45 : 50,
      fontSize: ({ isMobile }) => isMobile ? 16 : 18,
      fontFamily: customTheme.fontFamily.inter,
      borderRadius: 24,
      boxShadow: '0px 4px 4px rgba(31, 34, 41, 0.25)',
      '& .MuiButton-label': {
        padding: '0 20px',
        textTransform: 'none',
        [theme.breakpoints.down(420)]: {
          fontSize: 13,
          padding: '0 10px'
        }
      }
    },
    primary: {
      backgroundColor: customTheme.colors.blue,
      '& .MuiButton-label': {
        color: customTheme.colors.white
      },
      '&:hover': {
        backgroundColor: customTheme.colors.blue
      }
    },
    secondary: {
      backgroundColor: customTheme.colors.white,
      '& .MuiButton-label': {
        color: customTheme.colors.blue
      },
      '&:hover': {
        backgroundColor: customTheme.colors.white
      }
    },
    outlined: {
      backgroundColor: customTheme.colors.white,
      border: `2px solid ${customTheme.colors.blue}`,
      '& .MuiButton-label': {
        color: customTheme.colors.blue
      },
      '&:hover': {
        backgroundColor: customTheme.colors.white
      }
    }
  }),
  { name: 'Button' }
);
