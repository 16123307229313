//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../configs/theme/theme.config';
import coinsTop from '../../assets/img/coins.top.webp';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    root: {
      marginTop: ({ isMobile }) => isMobile ? 7 : 50,
      marginBottom: 20
    },
    picture: {
      minHeight: '35vw',
      background: `top / contain repeat-x url(${coinsTop})`,
      margin: '0 30px'
    },
    header: {
      height: ({ isMobile }) => isMobile ? 72 : 96,
      padding: ({ isMobile }) => isMobile ? '0 22px' : '0 40px',
      color: customTheme.colors.white,
      backgroundColor: customTheme.colors.yellow,
      fontSize: ({ isMobile }) => isMobile ? 22 : 36,
      fontFamily: customTheme.fontFamily.interBold,
      lineHeight: ({ isMobile }) => isMobile ? '72px' : '96px',
      borderRadius: '15px 15px 0 0'
    },
    container: {
      padding: ({ isMobile }) => isMobile ? '22px' : '45px 40px'
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: ({ isMobile }) => isMobile ? 20 : 25,
      height: ({ isMobile }) => isMobile ? 20 : 25,
      marginRight: ({ isMobile }) => isMobile ? 20 : 30,
      backgroundColor: customTheme.colors.blue,
      color: customTheme.colors.white,
      border: `2px solid ${ customTheme.colors.blue }`,
      borderRadius: '50%'
    },
    iconReverse: {
      backgroundColor: customTheme.colors.white,
      color: customTheme.colors.blue,
      border: `2px solid ${ customTheme.colors.blue }`
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: ({ isMobile }) => isMobile ? 25 : 38,
      fontSize: ({ isMobile }) => isMobile ? 14 : 18,
      lineHeight: ({ isMobile }) => isMobile ? '20px' : '22px',
      ['-webkit-text-size-adjust']: '100%'
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      margin: ({ isMobile }) => isMobile ? '30px 0 20px 0' : '40px 0 10px 0'
    }
  }),
  { name: 'Rules' }
);
