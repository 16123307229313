//#region imports
import { ThunkDispatch } from 'redux-thunk';

import { IRootState } from '../entities/root.reducer';
import { TLocaleState } from '../entities/locale/locale.reducer';
import { connect } from './connect';
import { changeLocale } from '../entities/locale/locale.actions';
//#endregion

const mapStateToProps = (state: IRootState): TLocaleState => state.locale;

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, unknown, any>) => ({
  changeLocale: (lng: string) => dispatch(changeLocale(lng))
});

export type TLocaleServiceProps = ReturnType<typeof mapStateToProps> & {
  actions: ReturnType<typeof mapDispatchToProps>;
};

export const LocaleService = connect<TLocaleServiceProps>('localeService', mapStateToProps, mapDispatchToProps);
