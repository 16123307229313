//#region imports
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//#endregion

const useMobile = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(1040));
  const isTablet = useMediaQuery(theme.breakpoints.down(1100));

  return { isMobile, isTablet };
};

export default useMobile;
