import i18n from '../configs/locales';

interface IChangeLocale {
  currentLocale: string;
  locales: string[];
  actions: {
    changeLocale: Function;
  };
}

export function changeLocale({ currentLocale, locales, actions }: IChangeLocale) {
  const newArr = locales.filter(el => el !== currentLocale);
  if (newArr?.length) {
    actions.changeLocale(newArr[0]);
    i18n.changeLanguage(newArr[0]);
  }
}
