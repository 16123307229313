//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useMobile from '../../hooks/use.mobile.hook';
import stylesPrizes from './prizes.styles';
import Button from '../../components/button/button';
import Wrapper from '../../components/wrapper/wrapper';
import banknote from '../../assets/svg/banknote.svg';
import cup from '../../assets/svg/cup.svg';
import { EButtonVariant } from '../../models/types';
import flow from 'lodash/fp/flow';
import { ModalService, TModalServiceProps } from '../../services/modal.service';
//#endregion

interface IPrizesProps {
  modalService: TModalServiceProps;
}

const Prizes: FC<IPrizesProps> = ({ modalService }) => {
  const { actions } = modalService;
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesPrizes({ isMobile });

  const prizesList = [...Array(8)].map((el, i) => {
    return (
      <div className={ classes.item } key={ i }>
        <img className={ classes.icon } src={ banknote }/>
        <span>{ t(`prizes.prize${i}`) }</span>
      </div>
    );
  });

  return (
    <div className={ classes.root }>
      <div className={ classes.picture } />
      <Wrapper className={ classes.wrapper }>
        <h3 className={ classes.header } id="prizes">{ t('prizes.header') }</h3>
        <section className={ classes.container }>
          <p className={ classes.label }>{ t('prizes.label') }</p>
          { prizesList }
          <div className={ classes.superPrizeContainer }>
            <img className={ classes.icon } src={ cup }/>
            <div className={ classes.superPrize }>
              <p className={ classes.textBold }>{ t('prizes.superPrizeLabel') }</p>
              <p className={ classes.amount }>{ t('prizes.superPrize') }</p>
            </div>
          </div>
          <div className={ classes.buttons }>
            <Button
              title={ t('prizes.buttonSubscribe') }
              isMobile={ isMobile }
              onClick={ actions.toggleModalSubscribe }
            />
            <Button
              variant={ EButtonVariant.outlined }
              title={ t('prizes.buttonUnsubscribe') }
              isMobile={ isMobile }
              onClick={ actions.toggleModalUnsubscribe }
            />
          </div>
        </section>
      </Wrapper>
    </div>
  );
};

export default flow([ModalService])(Prizes);
