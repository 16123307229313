//#region imports
import React, { FC } from 'react';
import ButtonUi from '@material-ui/core/Button';
import isFunction from 'lodash/isFunction';

import stylesButton from './button.styles';
import { EButtonVariant, EModalTypes } from '../../models/types';
//#endregion

interface IButtonProps {
  title: string;
  isMobile: boolean;
  className?: string;
  link?: string;
  type?: EModalTypes;
  variant?: EButtonVariant;
  onClick?: () => void;
}

const Button: FC<IButtonProps> = (
  { title,
    isMobile,
    className,
    link,
    type,
    variant = EButtonVariant.primary,
    onClick
  }) => {
  const classes = stylesButton({ isMobile });

  const handleModal = () => {
    if (link) {
      window.open(link, '_blank', 'noopener');
    } else isFunction(onClick) && onClick();
  };

  return (
    <ButtonUi className={ `${className} ${classes.button} ${classes[variant]}` } onClick={ handleModal }>
      { title }
    </ButtonUi>
  );
};

export default Button;
