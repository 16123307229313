import createTheme from '@material-ui/core/styles/createTheme';

export const configColors = {
  white: '#FFFFFF',
  grey: '#C4C4C4',
  yellow: '#FECA0A',
  blue: '#275EAC',
  dark: '#3A3A3A',
  neutral: '#8F8F8F'
};

export const fonts = {
  inter: 'Inter, Roboto, Arial, sans-serif',
  interBold: 'InterBold, Roboto, Arial, sans-serif'
};

export const customTheme = createTheme({
  fontFamily: fonts,
  colors: configColors as any
});
