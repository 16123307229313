//#region imports
import React, { FC, useMemo } from 'react';
import Drawer from '@material-ui/core/Drawer';
import flow from 'lodash/fp/flow';

import stylesModal from '../modal.styles';
import useMobile from '../../../hooks/use.mobile.hook';
import { ModalService, TModalServiceProps } from '../../../services/modal.service';
import ModalSubscribeContent from '../shared/modal.subscribe.content';
import ModalUnsubscribeContent from '../shared/modal.unsubscribe.content';
import { EModalTypes } from '../../../models/types';
//#endregion

interface IModalProps {
  modalService: TModalServiceProps;
}

const ModalMobile: FC<IModalProps> = ({ modalService }) => {
  const { show, type, actions } = modalService;
  const { isMobile } = useMobile();
  const classes = stylesModal({ isMobile });

  const renderContent = useMemo(() => {
    if (type === EModalTypes.SUBSCRIBE) {
      return (
        <ModalSubscribeContent toggleModal={ actions.closeModal }/>
      );
    } else {
      return (
        <ModalUnsubscribeContent toggleModal={ actions.closeModal }/>
      );
    }
  }, [show, type]);

  if (!show) {
    return null;
  }

  return (
    <Drawer
      className={ classes.wrapperMobile }
      open={ show }
      onClose={ actions.closeModal }
    >
      { renderContent }
    </Drawer>
  );
};

export default flow([ModalService])(ModalMobile);
